

























































































































































































































































































































































































/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import SvgAtSign from '@/components/svg/AtSign.vue';
import SvgCheck from '@/components/svg/Check.vue';
import SvgX from '@/components/svg/X.vue';
import { profiles as ProfileCountries } from '@/countries';
import { ApiResource } from '@/types';
import RegisterAccount from '@/components/Register.vue';
import { StripeError, TokenCreateParams } from '@stripe/stripe-js';

interface Form {
  [index: string]: any,
  accepts_our_terms: boolean,
  country: string|null,
  dob: Date|null,
  first_name: string,
  last_name: string,
  name: string,
  type: 'individual'|'company'|undefined,
  vanity: string,
}

interface FormValidation {
  [index: string]: string|null,
}

/** {@link} https://github.com/ffxsam/vue-typescript-cookbook */
interface VuexBindings {
  account: ApiResource.Account,
  isAuthenticated: boolean,
  isAvailable: (vanity: string) => boolean,
  isChecked: (vanity: string) => boolean,
  findVanity: (vanity: string) => ApiResource.Vanity|undefined,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    RegisterAccount,
    SvgAtSign,
    SvgCheck,
    SvgX,
  },
  data() {
    const country = this.$store.getters.COUNTRY;
    const isSupportedCountry = ProfileCountries.includes(country);

    return {
      checkVanityTimer: undefined as undefined|number,
      error: null as Error | StripeError | null,
      form: {
        accepts_our_terms: false,
        country: isSupportedCountry ? country : null,
        dob: new Date('2000-01-01'),
        first_name: '',
        last_name: '',
        name: '',
        type: undefined,
        vanity: '',
      } as Form,
      hasJustRegistered: false,
      isLoading: false,
      isSubmitting: false,
      registrationEmail: null,
      validation: {
        accepts_our_terms: null,
        country: null,
        dob: null,
        dob_day: null,
        dob_month: null,
        dob_year: null,
        first_name: null,
        last_name: null,
        name: null,
        type: null,
        vanity: null,
      } as FormValidation,
    };
  },
  computed: {
    ...mapGetters({
      account: 'account/Get',
      isAuthenticated: 'account/IsLoggedIn',
      isAvailable: 'vanity/IsAvailable',
      isChecked: 'vanity/IsChecked',
      findVanity: 'vanity/Find',
      country: 'COUNTRY',
    }),
    baseUrl(): string {
      return process.env.VUE_APP_BASE_URL || '';
    },
  },
  watch: {
    country(to): void {
      if (ProfileCountries.includes(to) && !this.form.country) this.form.country = to;
    },
  },
  methods: {
    handleError(e: Error): void {
      this.error = e;
    },
    async submit(): Promise<void> {
      console.log('[Create]', 'Submit');
      if (this.validate() || !this.form.dob) return;
      console.log('[Create]', 'Valid!');
      if (!this.isAuthenticated) return;
      console.log('[Create]', 'Logged in!');

      this.isSubmitting = true;

      const stripe = await this.$stripe().catch(this.handleError);
      if (!stripe) {
        this.isSubmitting = false;
        return;
      }

      const personalDetails: TokenCreateParams.Person = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        dob: {
          day: this.form.dob.getDate(),
          month: this.form.dob.getMonth() + 1,
          year: this.form.dob.getFullYear(),
        },
        email: this.account.email,
      };

      const account: TokenCreateParams.Account = {
        business_type: this.form.type,
        tos_shown_and_accepted: this.form.accepts_our_terms,
      };

      let personToken;

      if (this.form.type === 'individual') {
        account.individual = personalDetails;
      } else if (this.form.type === 'company') {
        account.company = {};

        personalDetails.relationship = {
          representative: true,
        };

        const { token, error } = await stripe.createToken(
          'person',
          personalDetails,
        );

        if (error) {
          this.error = error;
          return;
        }

        console.log('[Stripe.js]', '[Person]', token);
        personToken = token;
      }

      const { token, error } = await stripe.createToken(
        'account',
        account,
      );

      if (error || !token) {
        this.error = error || null;
        return;
      }

      console.log('[Stripe.js]', '[Account]', token);

      const data = await this.$store.dispatch('account/CreateBusiness', {
        form: {
          country: this.form.country,
          name: this.form.name,
          vanity: this.form.vanity,
          accepts_terms: this.form.accepts_our_terms,
          stripe_account_token: token.id,
          stripe_person_token: personToken ? personToken.id : undefined,
        },
      }).catch((e) => { this.error = e; });

      this.isSubmitting = false;

      if (!data) return;

      console.log(data);

      this.$router.push({
        name: 'dashboard.welcome',
        params: { VANITY: data.data.vanity },
      });
    },
    validate(validation: FormValidation = {}): boolean {
      const { form: F } = this;
      const v: FormValidation = { ...validation };

      if (!F.accepts_our_terms) {
        v.accepts_our_terms = 'trueVal';
      }

      if (!F.country) {
        v.country = 'notOptional';
      } else if (!ProfileCountries.includes(F.country)) {
        v.country = 'in';
      }

      if (!F.dob) {
        v.dob = 'notOptional';
      }

      if (!F.first_name) {
        v.first_name = 'notOptional';
      }

      if (!F.last_name) {
        v.last_name = 'notOptional';
      }

      if (!F.name) {
        v.name = 'notEmpty';
      } else if (F.name.length > 70) {
        v.name = 'length';
      }

      if (!F.type) {
        v.type = 'notOptional';
      } else if (!['individual', 'company'].includes(F.type)) {
        v.type = 'in';
      }

      if (!F.vanity) {
        v.vanity = 'notEmpty';
      } else if (F.vanity.length < 3) {
        v.vanity = 'length';
      } else if (F.vanity.length > 35) {
        v.vanity = 'length';
      }

      this.validation = v;
      // Check if all keys of v are null, will return false(=!true)
      return !Object.keys(v).every((k) => v[k] === null);
    },
    async check(): Promise<void> {
      const { vanity } = this.form;
      console.log('[Check]', vanity);

      this.validation.vanity = null;

      // Validate
      if (!vanity) {
        this.validation.vanity = 'notOptional';
        return;
      }

      if (vanity.length > 35) {
        this.validation.vanity = 'length';
        return;
      }

      if (!new RegExp(/[a-z0-9]/gi).test(vanity)) {
        this.validation.vanity = 'alnum';
        return;
      }

      this.isLoading = true;

      await this.$store.dispatch('vanity/Check', vanity)
        .catch((e) => { this.error = e; });

      this.isLoading = false;
    },
    checkVanityAfterTyping(): void {
      // Restart timer on keyup
      if (this.checkVanityTimer) window.clearTimeout(this.checkVanityTimer);
      this.checkVanityTimer = window.setTimeout(this.check, 650);
    },
    checkVanityIsTyping(): void {
      // Stop timer on keydown
      window.clearTimeout(this.checkVanityTimer);
    },
    registered(registration: any): void {
      console.log(registration);
      this.hasJustRegistered = true;
      this.registrationEmail = registration.credentials.email;
    },
  },
});
